import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import LeadForm from "../../../components/Forms/LeadForm";
import { directMailData } from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import bg1 from "../../../images/svg/vector-10.svg";
import Image from "../../../components/ImageQuerys/RandomImages";
import { DIRECT_MAIL_LP } from "../../../utils/firebase/forms";
import CapterraBanner from "../../../components-v2/CapterraBanner";

function NoMoneyDownPage({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+188859533536"
      phoneText="(888) 595-3536"
    >
      <SEO
        title="Restaurant POS System | Offer for New Restaurants | SpotOn"
        description="New restaurants get $500 off of SpotOn Restaurant. Streamline ops, create better guest experiences, retain employees, and boost revenue. Get started today."
        image={`https://spoton.com/${heroBg}`}
        robotsData="noindex, nofollow"
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading
            level={1}
            injectionType={1}
            withBlueSymbol
            className="max-w-[550px] mx-auto lg:mx-0"
          >
            Get started with the <span className="text-primary">#1-rated</span>{" "}
            restaurant POS
          </Heading>
          <div className="hidden lg:block text-black-300 text-[20px] max-w-[400px] xl:max-w-[530px] mt-10 mx-auto lg:mx-0">
            Opening a new restaurant means you have a lot on your plate. Let
            SpotOn support you with modern, easy-to-use tech. Point-of-sale,
            online ordering, staff management, and more, plus the industry's
            best customer support.
          </div>

          <div
            className="w-full my-6 hidden lg:block "
            style={{ borderTop: "1px solid #D6DADF" }}
          />

          <p className="hidden lg:block text-black-300 text-[12px]">
            *New SpotOn clients receive $500 off new restaurant point-of-sale
            hardware through May 31, 2023. Offer applies to new SpotOn
            Restaurant clients only and may not be combined with any other
            discounts, offers, or promotions. 
          </p>
        </div>

        <div className="relative -mt-12 md:-mt-4 lg:mt-0">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={directMailData}
            initialValues={extractDefaultValues(directMailData)}
            onValuesChange={() => null}
            useFormValueAsDataLayerEventName
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterest=""
            collection={DIRECT_MAIL_LP}
            thankYouPath="/lp/direct-mail/thank-you"
          />

          <div
            className="w-full my-6 lg:hidden "
            style={{ borderTop: "1px solid #D6DADF" }}
          />

          <p className="lg:hidden max-w-[500px] mt-6 mx-auto text-center text-black-300 text-[12px]">
            *New SpotOn clients with over $400K in annual sales receive free
            restaurant point-of-sale hardware through April 30, 2023. Offer
            applies to new SpotOn Restaurant clients only and may not be
            combined with any other discounts, offers, or promotions. Offer
            valid in Pennsylvania, Florida, Washington, and Washington, D.C.
            only.
          </p>
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <CapterraBanner
        className="mt-10 lg:mt-20"
        imageName="capterra-pos.png"
        title={`The <span class="text-primary">top-rated POS</span> company according to actual user reviews<span class="text-primary">.</span>`}
        subtitle="Real restaurant operators awarded SpotOn the highest marks for restaurant point-of-sale across every quality measured, beating out Square, Clover, and Toast."
        withCta={false}
      />

      <Section className="mt-16 lg:mt-24 mb-20">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-[1120px] mx-auto">
          <div className="bg-primary-30 rounded-[16px] p-8 md:p-12 flex flex-col items-center justify-center">
            <p className="text-black text-p md:text-[24px]">
              “SpotOn is not just a POS. It's a communications device. It's a
              reporting device. It's a labor management tool. If you’re looking
              for a POS system that going to help you run your business, it’s
              SpotOn.”
            </p>

            <div className="mt-4 xl:mt-12 flex self-start">
              <div className="w-[80px]">
                <Image imageName="gotham-logo-1.png" />
              </div>
              <div className="ml-4">
                <div className="text-primary text-p md:text-[24px]">
                  Bret Csencsitz
                </div>
                <div className="text-black-200 text-[12px] md:text-[14px]">
                  Managing Partner, Gotham Restaurant
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <Image imageName="direct-mail-img.png" />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default NoMoneyDownPage;
